import {
  IconBrandInstagram,
  IconBrandTwitter,
  IconBrandYoutube,
} from "@tabler/icons-react";
import { ActionIcon, Anchor, Group, Text } from "@mantine/core";
import "./styles.scss";
import { Images } from "../../lib/Data";
import { Link } from "react-router-dom";
const links = [
  { link: "#contact", label: "Contact" },
  { link: "#start", label: "Get Started" },
  { link: "#", label: "Privacy Policy" },
  { link: "#unlocks", label: "How it Works" },
];

export default function Footer() {
  const items = links.map((link) => {
    return (
      <>
        {link.label === "Get Started" ? (
          <Link to="/start">
            <Text c="dimmed" key={link.label} lh={1} size="sm"></Text>
          </Link>
        ) : (
          <Anchor c="dimmed" key={link.label} href={link.link} lh={1} size="sm">
            {link.label}
          </Anchor>
        )}
      </>
    );
  });

  return (
    <div className="footer-container">
      <div className="footer">
        <div className="inner">
          <img src={Images.LogoBlackImage} alt="" className="logo" />

          <Group className="links">{items}</Group>

          <Group gap="xs" justify="flex-end" wrap="nowrap">
            <ActionIcon size="lg" variant="default" radius="xl">
              <IconBrandTwitter size={18} stroke={1.5} />
            </ActionIcon>
            <ActionIcon size="lg" variant="default" radius="xl">
              <IconBrandYoutube size={18} stroke={1.5} />
            </ActionIcon>
            <ActionIcon size="lg" variant="default" radius="xl">
              <IconBrandInstagram size={18} stroke={1.5} />
            </ActionIcon>
          </Group>
        </div>
      </div>
    </div>
  );
}
