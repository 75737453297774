import { useNavigate } from "react-router-dom";

import { Container } from "@mui/material";

import { Carousel } from "@mantine/carousel";
import { Accordion, Button, Card, Text } from "@mantine/core";
import { useViewportSize } from "@mantine/hooks";

import Navbar from "../../components/Navbar";

import { AppName, Colors, FrequentlyAsked, Images, Unlocks } from "../../lib/Data";

import ContactUs from "../../components/ContactUs";
import Footer from "../../components/Footer";
import { getRandomAnimationDirection } from "../../lib/Methods";
import "./styles.scss";

const HomePage: React.FC = () => {
  const navigate = useNavigate();
  const { width } = useViewportSize();

  return (
    <>
      <Navbar />
      <div className="homepage-container">
        <div id="start" className="jumbotron ">
          <Container maxWidth="lg">
            <div className="content flex-row align-center justify-between">
              <div className="flex-col left">
                <Text mb={20} className="header" c={Colors.White}>
                  Turn Your Vehicle Into Cash with Ease
                </Text>
                <Text mb={20} className="desc" c={Colors.White}>
                  Transform your vehicle into cash effortlessly. {AppName}&nbsp;
                  streamlines the process of selling your car, ensuring a hassle-free
                  experience from start to finish. Our platform provides all the essential
                  tools and guidance you need to list your vehicle with ease.
                </Text>
                <Button
                  color={Colors.White}
                  c={Colors.GreenPrimary}
                  className="btn"
                  w={140}
                  h={50}
                  onClick={() => {
                    navigate("/start");
                  }}
                >
                  Get Started
                </Button>
              </div>
              <img src={Images.CarsSingleImage} alt="" className="right" />
            </div>
          </Container>
        </div>

        <Container maxWidth="lg">
          <div
            id="unlocks"
            data-aos={getRandomAnimationDirection()}
            className="unlock-potential flex-col align-center"
          >
            <div className="unlock-bg"></div>
            <Text className="section-header">
              Unlock Your Vehicle's Cash Potential with {AppName}
            </Text>
            <div className="flex-row align-center justify-between">
              {Unlocks.map((unlock, index) => {
                return (
                  <Card
                    data-aos={getRandomAnimationDirection()}
                    withBorder
                    key={`${index}-${unlock.title}`}
                    className="unlock unlock-item"
                  >
                    <div
                      className="image"
                      style={{
                        background: `url(${unlock.image})`,
                      }}
                    />
                    <Text className="title">{unlock.title}</Text>
                    <Text className="desc">{unlock.desc}</Text>
                  </Card>
                );
              })}
            </div>
            {width < 720 && (
              <Carousel
                data-aos={getRandomAnimationDirection()}
                withIndicators
                classNames={{
                  indicator: "unlock-indicator",
                }}
                withControls={false}
                height={400}
                slideGap="md"
                loop
              >
                {Unlocks.map((unlock, index) => {
                  return (
                    <Carousel.Slide key={`${index}-${unlock.title}`}>
                      <Card withBorder className="unlock unlock-slide">
                        <div
                          className="image"
                          style={{
                            background: `url(${unlock.image})`,
                          }}
                        />
                        <Text className="title">{unlock.title}</Text>
                        <Text className="desc">{unlock.desc}</Text>
                      </Card>
                    </Carousel.Slide>
                  );
                })}
              </Carousel>
            )}
          </div>
          <br />
          <br />
          <div
            id="faq"
            data-aos={getRandomAnimationDirection()}
            className="frequently-asked flex-col align-center width-100"
          >
            <Text className="section-header">Frequently Asked Questions</Text>
            <Accordion w="100%">
              {FrequentlyAsked.map((question) => {
                return (
                  <Accordion.Item key={question.value} value={question.value}>
                    <Accordion.Control>
                      <Text fw={600}>{question.value}</Text>
                    </Accordion.Control>
                    <Accordion.Panel>{question.description}</Accordion.Panel>
                  </Accordion.Item>
                );
              })}
            </Accordion>
          </div>
          <br />
          <br />
          <div
            data-aos={getRandomAnimationDirection()}
            className="contact-us-section flex-col align-center width-100"
          >
            <Text className="section-header">Get In Touch</Text>
            <ContactUs />
          </div>
        </Container>
      </div>

      <Footer />
    </>
  );
};

export default HomePage;
