import { Table } from "@mantine/core";
import React from "react";

interface TabularDataProps {
  children: React.ReactNode;
  headers: string[];
}
export default function TabularData({ children, headers }: TabularDataProps) {
  return (
    <Table striped highlightOnHover withTableBorder withColumnBorders>
      <Table.Thead>
        <Table.Tr>
          {headers.map((h, index) => {
            return <Table.Th key={`${index}-${h.toString()}`}>{h}</Table.Th>;
          })}
        </Table.Tr>
      </Table.Thead>
      {children}
    </Table>
  );
}
