import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

// MANTINE IMPORTS
import { createTheme as createMantineTheme, Loader, MantineProvider } from "@mantine/core";
import { Notifications } from "@mantine/notifications";
import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";
import "@mantine/dropzone/styles.css";
import "@mantine/notifications/styles.css";
import "@mantine/carousel/styles.css";

import Aos from "aos";

import HomePage from "./pages/Home";
import SingleVehicle from "./pages/ViewVehicle";

import "./styles/index.scss";
import "aos/dist/aos.css";
import Start from "./pages/Start";

const mantineTheme = createMantineTheme({
  components: {
    Loader: Loader.extend({
      defaultProps: {
        type: "bars",
      },
    }),
  },
});
console.log(process.env.REACT_APP_BASE_URL);

export default function App() {
  Aos.init();

  return (
    <MantineProvider theme={mantineTheme}>
      <Notifications position="bottom-right" />
      <Router>
        <Routes>
          <Route index element={<HomePage />} path="/" />
          <Route element={<SingleVehicle />} path="/vehicle/:vehicleID" />
          <Route element={<Start />} path="/start" />
        </Routes>
      </Router>
    </MantineProvider>
  );
}
