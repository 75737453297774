const baseURL = process.env.REACT_APP_BASE_URL;
// const baseURL = "http://localhost:8080";

export const Endpoints = {
  GetCreateNewSaleRequest: () => `${baseURL}/client/request/new`,
  GetUploadFile: () => `${baseURL}/file/upload`,
  GetSendAdminMessage: () => `${baseURL}/client/message`,
  GetSingleVehicleDetails: (vehicleID: string) => `${baseURL}/client/inventory/${vehicleID}`,
  GetVehicleDetailsWithVIN: (vin: string) => `https://vpic.nhtsa.dot.gov/api/vehicles/decodevin/${vin}?format=json`,
};
