import { Loader, MantineColor } from "@mantine/core";

interface MegaLoaderProps {
  size?: number;
  color?: MantineColor;
  type?: "bars" | "oval" | "dots";
  containerStyle?: React.CSSProperties;
}
export default function MegaLoader({
  size = 60,
  type = "bars",
  containerStyle = {
    padding: 30,
  },
  color = "blue",
}: MegaLoaderProps) {
  return (
    <>
      <div
        style={containerStyle}
        className="flex-row align-center width-100 justify-center"
      >
        <Loader size={size} color={color} type={type} />
      </div>
    </>
  );
}
