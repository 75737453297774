import { Text, Burger, Container, Group, Button } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";

import { Colors, Images } from "../../lib/Data";
import classes from "./styles.module.css";
import "./styles.scss";
import { useNavigate } from "react-router-dom";
const links = [
  { link: "#unlocks", label: "How it Works" },
  { link: "#faq", label: "FAQs" },
  { link: "#contact", label: "Contact Us" },
];

export default function Navbar() {
  const navigate = useNavigate();

  const [opened, { toggle }] = useDisclosure(false);

  const items = links.map((link) => {
    return (
      <a key={link.label} href={link.link} className={classes.link}>
        {link.label}
      </a>
    );
  });

  return (
    <div className="navbar-container">
      <header className={classes.header}>
        <Container size="md">
          <div className={classes.inner}>
            <img src={Images.LogoBlackImage} alt="" className="logo" />
            <Group gap={5} visibleFrom="sm">
              {items}
            </Group>

            <Burger
              display="none"
              opened={opened}
              onClick={toggle}
              size="sm"
              hiddenFrom="sm"
            />
            <Group gap={5} hiddenFrom="sm">
              <Button
                color={Colors.GreenPrimary}
                variant="outline"
                c={Colors.GreenPrimary}
                className="btn"
                w={110}
                h={37}
                onClick={() => {
                  navigate("/start");
                }}
              >
                Get Started
              </Button>
            </Group>
          </div>
        </Container>
      </header>
    </div>
  );
}
