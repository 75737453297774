import React, { useEffect } from "react";

import { useNavigate, useParams } from "react-router-dom";

import { Container } from "@mui/material";

import { Button, Divider, Modal, Table, Text } from "@mantine/core";
import { IconChevronLeft, IconReload } from "@tabler/icons-react";

import dayjs from "dayjs";

import DisplayAlert from "../../components/DisplayAlert";
import MegaLoader from "../../components/MegaLoader";
import TabularData from "../../components/TabularData";

import { Endpoints } from "../../lib/Endpoints";
import { NonPaginatedResponse } from "../../lib/Responses";
import { VehicleProps, VinAPIResponse } from "../../lib/Types";
import { usePerformRequest } from "../../lib/usePerformRequest";

import { Colors } from "../../lib/Data";

import AnimateOnLoad from "../../components/AnimateOnLoad";
import { getFinancialValueFromNumeric } from "../../lib/Methods";
import "./styles.scss";
import { useDisclosure } from "@mantine/hooks";
import { Carousel } from "@mantine/carousel";

const SingleVehicle: React.FC = () => {
  const navigate = useNavigate();
  const { vehicleID } = useParams();
  const {
    data: r,
    isLoading: isLoadingRequest,
    reloadData: reloadRequest,
  } = usePerformRequest<NonPaginatedResponse<VehicleProps>>({
    method: "GET",
    route: Endpoints.GetSingleVehicleDetails(vehicleID as string),
  });
  const request = r && r.data && r.data.id ? r.data : undefined;
  const {
    data: rVin,
    isLoading: isLoadingVinDetails,
    reloadData: reloadVinDetails,
  } = usePerformRequest<VinAPIResponse>({
    method: "GET",
    route: Endpoints.GetVehicleDetailsWithVIN(request?.vin as string),
  });
  const [isImagesModalOpen, { open: OpenImagesModal, close: CloseImagesModal }] = useDisclosure(false);
  useEffect(() => {
    if (r && r.data && r.data.id) {
      reloadVinDetails();
    }
  }, [r]);

  return (
    <AnimateOnLoad>
      <Container maxWidth="lg">
        {isLoadingRequest ? (
          <MegaLoader />
        ) : (
          <>
            {request && request.id ? (
              <>
                <div className="single-vehicle-container">
                  <Text fw={500} fz={27} tt="capitalize">
                    {request.year} {request.manufacturer} {request.model}{" "}
                  </Text>
                  <TabularData headers={["Manufacturer", "Model", "Year"]}>
                    <Table.Tbody>
                      <Table.Tr>
                        <Table.Td key={request.manufacturer}>{request.manufacturer}</Table.Td>
                        <Table.Td key={request.model}>{request.model}</Table.Td>
                        <Table.Td key={request.year}>{request.year}</Table.Td>
                      </Table.Tr>
                    </Table.Tbody>
                  </TabularData>
                  <br />
                  <TabularData headers={["VIN", "Driveable"]}>
                    <Table.Tbody>
                      <Table.Tr>
                        <Table.Td key={request.vin} c={Colors.BluePrimary}>
                          {request.vin}
                        </Table.Td>
                        <Table.Td fw={600} key={"driveable"} c={request.driveable ? Colors.GreenPrimary : Colors.RedPrimary}>
                          {request.driveable ? "Yes" : "No"}
                        </Table.Td>
                      </Table.Tr>
                    </Table.Tbody>
                  </TabularData>
                  <Divider mt={30} mb={20} />
                  <div className="flex-row align-center">
                    <Text mr={15} fw={500} fz={27}>
                      Vehicle Images
                    </Text>
                  </div>
                  <br />
                </div>
                <div className="vehicle-images">
                  <Carousel withIndicators height={500}>
                    {request.images.map((image, index) => {
                      return (
                        <Carousel.Slide key={index}>
                          <div
                            className="image"
                            style={{
                              backgroundImage: `url(${image})`,
                            }}
                          ></div>
                        </Carousel.Slide>
                      );
                    })}
                  </Carousel>
                </div>
                <Modal
                  centered
                  opened={isImagesModalOpen}
                  onClose={CloseImagesModal}
                  title="Vehicle Images"
                  size="xl"
                  className="vehicle-images"
                ></Modal>
              </>
            ) : (
              <DisplayAlert
                title="No Vehicle Found"
                children={
                  <div className="flex-col">
                    <Text>No existing vehicle matches this request!</Text>
                    <Button
                      onClick={() => {
                        navigate("/");
                      }}
                      mt={20}
                      color={Colors.BluePrimary}
                      leftSection={<IconChevronLeft size={17} />}
                      w={170}
                    >
                      Back to Home
                    </Button>
                  </div>
                }
              />
            )}
          </>
        )}
      </Container>
    </AnimateOnLoad>
  );
};

export default SingleVehicle;
