import { Button, ButtonProps } from "@mantine/core";

import "./styles.scss";
import React from "react";
interface CustomMantineButtonProps extends ButtonProps {}
export default function CustomMantineButton({
  ...props
}: CustomMantineButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>) {
  return <Button className="custom-mantine-btn" {...props}></Button>;
}
