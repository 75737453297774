import React from "react";
import { Container } from "@mui/material";
import { Alert, MantineColor } from "@mantine/core";
import { IconInfoCircle } from "@tabler/icons-react";

interface DisplayAlertProps {
  containerSize?: "xs" | "sm" | "md" | "lg" | "xl";
  Icon?: typeof IconInfoCircle;
  color?: MantineColor;
  children: React.ReactNode;
  title?: string;
}
export default function DisplayAlert({ containerSize = "sm", color = "blue", Icon = IconInfoCircle, children, title = "Alert!" }: DisplayAlertProps) {
  return (
    <Container maxWidth={containerSize}>
      <Alert mt={50} variant="light" color={color} title={title} icon={<Icon />}>
        {children}
      </Alert>
    </Container>
  );
}
